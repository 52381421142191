<template>
  <v-container fluid>
    <SharedToolbar />
    <v-card>
      <v-card-title>サインイン</v-card-title>
      <v-card-text>
        <sign-in @success="onSuccess" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import { adminGetAdmin } from '@/services/functions'
import SharedToolbar from '../components/SharedToolbar'
import SignIn from '@/components/SignIn'

export default {
  components: {
    SharedToolbar,
    SignIn
  },
  methods: {
    ...mapMutations(['setUId', 'setAdmin', 'setSignInDone']),
    async onSuccess (data) {
      const { uid, email } = data
      const { data: admin } = await adminGetAdmin(email)
      this.setSignInDone(true)
      if (admin) {
        this.setUId(uid)
        this.setAdmin(admin)
        this.$router.replace('/reports')
      } else {
        this.$router.replace('/invalidAdmin')
      }
    },
    onFail () {
    }
  }
}
</script>
